import React from 'react';
import { SiKnowledgebase } from 'react-icons/si';
import { BsTools, BsHeart, BsBag } from 'react-icons/bs';
import Competetive from '../../../components/Competetive';
import Inovative from '../../../components/Inovative';
import Returns from '../../../components/Returns';
import Holistic from '../../../components/Holistic';

const HomeThreeSecondServices = () => {
   return (
      <>
         <section className="services__area-3">
            <div className="container" style={{maxWidth:"max-content"}}>
               <div className="row">
                  <div className="col-xl-6" style={{ background: `url(assets/img/services/5bg.png)`}}>
                     <div className="section-title section__title-3 mb-70" style={{marginLeft:"17%"}}>
                        <h2>A <span 
                           style={{position:"relative", backgroundImage:"linear-gradient(112deg, #5a6ef8, #fe7a8f)"
                              , backgroundClip:"text",WebkitTextFillColor:"transparent", fontSize:"3rem"
                           }}> Gender </span> <br/>Diverse Workforce </h2>
                        <p>We help you weather today's uncertainty through our best team.</p>
                     </div>
                  </div>
               </div>
               <div className="row" >
                  <div className="col-xl-12" >
                     <div className="services__nav wow fadeInUp" data-wow-delay=".4s" style={{display:"flex", justifyContent:"center"}}>
                        <ul className="nav nav-pills " id="services-tab" role="tablist"  >
                           <li className="nav-item mb-45" >
                              <a className="nav-link active" id="share-tab" data-bs-toggle="pill" href="#share" role="tab" aria-controls="share" aria-selected="true" >
                                 <i> <SiKnowledgebase/> </i>Innovative
                                 
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="customer-tab" data-bs-toggle="pill" href="#customer" role="tab" aria-controls="customer" aria-selected="true">
                                 <i ><img
                                 src="assets/img/services/unisex.png"
                                 />
                                 </i> Competetive
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="social-tab" data-bs-toggle="pill" href="#social" role="tab" aria-controls="social" aria-selected="true">
                                 <i ><BsHeart/></i> Better Returns
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="financial-tab" data-bs-toggle="pill" href="#financial" role="tab" aria-controls="financial" aria-selected="true">
                                 <i ><img
                                 src="assets/img/services/genderqueer.png"
                                 /></i> Holistic Approach
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-12" style={{ background: `url(assets/img/services/circle.png)`,backgroundRepeat:"no-repeat", backgroundPositionX:"125%", backgroundPositionY:"-90% " }}>
                     <div className="services__tab grey-bg-18"  style={{background:"transparent"}}  >
                        <div className="tab-content" id="services-content" >
                           <div className="tab-pane fade show active" id="share" role="tabpanel" aria-labelledby="share-tab" >
                              <Inovative title="Innovative" />
                           </div>
                           <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                              <Competetive title="Competetive" />
                           </div>
                           <div className="tab-pane fade" id="social" role="tabpanel" aria-labelledby="social-tab">
                              <Returns title="Better Returns" />
                           </div>
                           <div className="tab-pane fade" id="financial" role="tabpanel" aria-labelledby="financial-tab">
                              <Holistic title="Holistic Approach" />
                           </div>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeSecondServices;