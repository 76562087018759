import React from 'react';
import { Link } from 'react-router-dom';

const HomeTwoAchievement = () => {
   return (
      <>
         <section className="achievement__area pt-135 pb-40">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-6">
                     <div className="achievement__content">
                        <div className="section__title section__title-3 mb-20">
                           <span>Thinking</span>
                           <h2> <span 
                           style={{position:"relative", backgroundImage:"linear-gradient(112deg, #5a6ef8, #fe7a8f)"
                              , backgroundClip:"text",WebkitTextFillColor:"transparent", fontSize:"4rem"
                           }}>
                            How to fix </span> the lopsided rules of the game
                           </h2>
                        </div>
                        <p>The only way to prevent this runaway accumulation of the fruits of technology’s rise are to fix the lopsided rules of the game. Making tech a more welcome place for minorities and women should be our priority. Looking like our customers is good for business because “D&I is RoI”.</p>
                        <div className="achievement__wrapper d-flex d-md-block d-lg-flex justify-content-between mb-35 wow fadeInUp" data-wow-delay=".8s">
                           <div className="achievement__item mb-30" style={{display:"inline-grid"}}>
                              <img src="assets/img/icon/achievement/achievement-1.png" alt="achievement"/>

                                 {/* <h3>Individuals</h3> */}
                                 <Link to="" className="default-btn2">Individuals</Link>

                           </div>
                           <div className="achievement__item mb-30"  style={{display:"inline-grid"}}>
                              <img src="assets/img/icon/achievement/achievement-2.png" alt="achievement"/>
                                 {/* <h3>Employers</h3> */}

<Link to="" className="default-btn2">Employers</Link>

                           </div>
                        </div>
                        {/* <Link to="/about" className="z-btn">Learn More</Link> */}
                     </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6">
                     <div className="row">
                        <div className="col-xl-7 col-sm-6">
                           <div className="achievement__thumb m-img pl-30 text-right">
                              <img src="assets/img/achievement/achievement-1.jpg" alt="achievement-1"/>
                           </div>
                        </div>
                        <div className="col-xl-5 col-sm-6 d-md-none d-lg-none d-xl-block ">


                        <div className="features__items   fix mt-10 ">
<div className="features__thumb-2 default-active">
   <img src="assets/img/achievement/achievement-2.jpg" alt=""  style={{width:"inherit"}} />
   <img src="assets/img/services/7bg.png" />
</div>

</div>  



                         
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoAchievement;