import React from 'react';
import { BiLock } from 'react-icons/bi';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaLightbulb, FaRibbon, FaAngleDoubleDown } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import HomeTwoSingleFeature from '../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature';

const HomeTwoFeatures = () => {
   return (
      <>
         <section className="features__area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="features__content-left">
                        <div className="section__title section__title-h2 mb-25">
                           <span>Let's fill</span>
                           <h2 >  The <span style={{position:"relative", backgroundImage:"linear-gradient(112deg, #5a6ef8, #fe7a8f)"
                           , backgroundClip:"text",WebkitTextFillColor:"transparent", fontSize:"4rem"
                        }}>workforce </span>gap</h2>
                        </div>
                        <p >Women are 51% of the workforce, however women are only 25% of the tech workforce.</p>
                        <Link to="/" className="z-btn-3">Read More<i><CgArrowLongRight/></i></Link>
                     </div>
                  </div>
                  {/* <div className="col-xl-6 offset-xl-1 col-lg-6">
                     <div className="features__content-right">
                        <div className="row">
                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature icon={<FaLightbulb />} title="Share Knowledge" class_name=""/>
                              <HomeTwoSingleFeature icon={<IoDocumentTextOutline />} title="Amazing Works" class_name="default-active" />

                           </div>

                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature icon={<FaRibbon />} title="Meet new Friends" class_name="default-active" />
                              <HomeTwoSingleFeature icon={<BiLock />} title="Portfolio Expert" class_name="" />

                           </div>
                        </div>
                     </div>
                     
                  </div> */}

                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                     <div className="features__content-right">
                        <div className="row">
                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                           <div className="features__item features__item-2 white-bg fix mb-30">
            <div className="features__thumb-2 " style={{ background: `url(assets/img/features/02/features-1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover'}} ></div>
            <div className="features__content-2">
               <div className="features__icon features__icon-2">
               <i > {< FaLightbulb />} </i>

               </div>
               <h3>Are females underrepresented in tech? </h3>
               {/* <p>He nicked it Jeffrey zonked cheeky bugger.</p> */}
               <div className="features__btn-2">
               <Link to="" className="link-btn">
                     <i > <CgArrowLongRight /> </i>
                     <i > <CgArrowLongRight /> </i>
                  </Link>
               </div>
            </div>
         </div>
                           </div>

                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                           <div className="features__item features__item-2 white-bg fix mb-30">
            <div className="features__thumb-2 default-active" style={{ background: `url(assets/img/features/02/features-1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover'}} ></div>
            <div className="features__content-2">
               <div className="features__icon features__icon-2">
               <i  style={{color:"white"}}> <FaRibbon /> </i>
               </div>
               <h3 style={{color:"white"}}>Women in tech is less than 3 in 10.</h3>
               <p style={{color:"white"}}>In 2022 (Ref. Anitab)</p>
               <div className="features__btn-2">
               <Link to="" className="link-btn">
                     <i style={{color:"white"}}> <CgArrowLongRight /> </i>
                     <i  style={{color:"white"}}> <CgArrowLongRight /> </i>
                  </Link>
               </div>
            </div>
         </div>       </div>

         <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

<div className="features__item features__item-2 white-bg fix mb-30">
<div className="features__thumb-2 default-active" style={{ background: `url(assets/img/features/02/features-1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover'}} ></div>
<div className="features__content-2">
<div className="features__icon features__icon-2">
<i  style={{color:"white"}}> <IoDocumentTextOutline /> </i>
</div>
<h3 style={{color:"white"}}>50% of women in tech </h3>
<p style={{color:"white"}}>Leave their job by age 35.</p>
<div className="features__btn-2">
<Link to="" className="link-btn">
<i style={{color:"white"}}> <CgArrowLongRight /> </i>
<i  style={{color:"white"}}> <CgArrowLongRight /> </i>
</Link>
</div>
</div>
</div>       </div>

<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

<div className="features__item features__item-2 white-bg fix mb-30">
<div className="features__thumb-2 " style={{ background: `url(assets/img/features/02/features-1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover'}} ></div>
<div className="features__content-2">
<div className="features__icon features__icon-2">
<i> <FaAngleDoubleDown /> </i>
</div>
<h3 >The number increase less than 1%</h3>
<p>Women in tech in 2022</p>
<div className="features__btn-2">
<Link to="" className="link-btn">
<i> <CgArrowLongRight /> </i>
<i> <CgArrowLongRight /> </i>
</Link>
</div>
</div>
</div>       </div>
                        </div>
                     </div>
                     
                  </div>
                  
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoFeatures;